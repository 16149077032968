import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Helmet } from "react-helmet-async";
const RootStyle = styled(Box)(() => ({
  minHeight: "100%",
  overflow: "hidden",
}));
const Page = forwardRef(({ children, title = "", meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | Familysooq`}</title>
    </Helmet>
    <RootStyle ref={ref} {...other}>
      {children}
    </RootStyle>
  </>
));
Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};
export default Page;
