import React from 'react'
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import * as PANOLENS from "panolens";
import { useRef, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./custom.css";
const useStyles = makeStyles((theme) => ({
  root: {},
  nav: {
    transition: "all .3s ease-out",
    "-webkit-appearance": "none",
    border: 0,
    cursor: "pointer",
    outline: "none",
    position: "absolute",
    top: "44%",
    zIndex: 4,
  },
  navLeft: {
    left: 10,
  },
  navRight: {
    right: 10,
  },
}));
export default function Gallery({
  images,
  showBullets,
  showThumbnails = true,
  showFullscreenButton,
}) {
  const classes = useStyles();
  const [panOpen, setPanOpen] = useState(false);
  const myImageRef = useRef(null);
  const temp = document.querySelector("#pan-id");
  const panDisplay = () => {
    if (panOpen) return;
    if (temp === null || temp === undefined) return;
    const viewer = new PANOLENS.Viewer({
      container: temp,
    });
    setPanOpen(true);
    viewer.width = "100%";
    viewer.height = "100%";
  };
  const Pan = () => {
    return (
      <div
        id="pan-id"
        onClick={panDisplay}
        style={{
          height: "380px",
          width: "100%",
        }}
      ></div>
    );
  };
  const ThumbController = () => {
    return (
      <span className="image-gallery-thumbnail-inner" style={{}}>
        <img src="https://picsum.photos/200" alt="" />
      </span>
    );
  };
  const imgs = images.map((item, ind) =>
    false
      ? {
          renderItem: () => Pan(),
          renderThumbInner: ThumbController,
          original: "https://picsum.photos/seed/picsum/900/300",
          thumbnail: "https://picsum.photos/id/1018/250/150/",
        }
      : {
          original: item.image,
          thumbnail: item.image,
        }
  );
  const renderLeftNav = (onClick, disabled, full) => {
    return (
      <IconButton
        sx={{
          backgroundColor: full ? "rgba(0, 0, 0, 0.55)" : "white",
          color: full ? "white" : "black",
          boxShadow: " 1px 1px 5px 1px rgba(0, 0, 0, 0.25)",
        }}
        className={clsx(classes.nav, classes.navLeft)}
        disabled={disabled}
        onClick={onClick}
        size="small"
      >
        <ChevronLeftIcon fontSize="medium" />
      </IconButton>
    );
  };
  const renderRightNav = (onClick, disabled, full) => {
    return (
      <IconButton
        sx={{
          backgroundColor: full ? "rgba(0, 0, 0, 0.65)" : "white",
          color: full ? "white" : "black",
          boxShadow: " 1px 1px 5px 1px rgba(0, 0, 0, 0.25)",
        }}
        className={clsx(classes.nav, classes.navRight)}
        disabled={disabled}
        onClick={onClick}
        size="small"
      >
        <ChevronRightIcon fontSize="medium" />
      </IconButton>
    );
  };
  const [full, setFull] = useState(false);
  const onImageClick = () => {
    setPanOpen(true);
  };
  const [photoIndex, setphotoIndex] = useState(0);

  return (
    <div>
      <ImageGallery
        additionalClass={full ? "fs-gallery-full" : "fs-gallery-normal"}
        ref={myImageRef}
        items={imgs}
        showPlayButton={false}
        showNav={false}
        showBullets={showBullets}
        showThumbnails={showThumbnails}
        showFullscreenButton={full}
        thumbnailHeight={10}
        useWindowKeyDown={true}
        onClick={onImageClick}
        lazyLoad={true}
        showIndex={full}
      />
      {panOpen && (
        <Lightbox
          mainSrc={images[photoIndex].image}
          nextSrc={images[(photoIndex + 1) % images.length].image}
          prevSrc={
            images[(photoIndex + images.length - 1) % images.length].image
          }
          onCloseRequest={() => setPanOpen(false)}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
}
