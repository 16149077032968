import React from "react";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import "react-lazy-load-image-component/src/effects/blur.css";
import { BrowserRouter } from "react-router-dom";
import "simplebar/src/simplebar.css";
import App from "./App";
import "./fonts/RussoOne-Regular.ttf";
import "./i18n";
import LoadingDots from "./LoadingDots";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense fallback={<LoadingDots />}>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want to enable client cache, register instead.
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
