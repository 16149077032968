import React from 'react'
import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Snackbar from "@mui/material/Snackbar";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
function withMutate(Component) {
  return function WihLoadingComponent({
    posting,
    postIsSuccess,
    postIsError,
    postSuccessMsg,
    postErrorMsg,
    ...props
  }) {
    const [open, setOpen] = useState(postIsSuccess);
    useEffect(() => {
      setOpen(postIsSuccess);
    }, [postIsSuccess]);
    useEffect(() => {
      setOpen(postIsError);
    }, [postIsError]);
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };
    const { t } = useTranslation();
    return (
      <Fragment>
        {posting ? (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={handleClose}
            sx={{ right: { sm: 80 } }}
          >
            <Alert
              onClose={handleClose}
              severity={postIsSuccess ? "success" : "error"}
              sx={{ width: "100%" }}
            >
              {postIsSuccess && t(postSuccessMsg)}
            </Alert>
          </Snackbar>
        )}
        <Component {...props} />
      </Fragment>
    );
  };
}
export default withMutate;
