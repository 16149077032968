import React from 'react'
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomizedBox from "../Feedback/CustomizedBox";
function Description({ data }) {
  const { t } = useTranslation();
  return (
    <CustomizedBox
      sx={{
        my: 1.5,
        pb: 0,
        border: "1px solid",
        borderColor: "divider",
        boxShadow: "none",
        width: "100%",
      }}
    >
      <Box width="100%" mb={1}>
        <Typography variant="h6">{t("Description")}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          flexWrap: "wrap",
          fontSize: "15px",
          color: "text.secondary",
          width: "100%",
          my: 2,
          whiteSpace: "pre-wrap",
        }}
      >
        {data}
      </Box>
    </CustomizedBox>
  );
}
export default Description;
