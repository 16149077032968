import React, { useEffect, useState } from "react";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CustomIcon } from "../../config/images/telebirr.svg";
import {
  useTelebirrAuthHook,
  useTelebirrAuthLoginHook,
} from "../../hooks/Accounts";
import { LOGIN, load_user as loadUser } from "../../redux/actions/auth";
import Alert from "../Alert/Alert";
import { urlGenerator } from "./Activation";

function AuthSocial({ loadUser, login }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();
  const [notify, setNotify] = useState(false);
  const [errors, setErrors] = useState({});
  const { isLoading, ...authMutate } = useTelebirrAuthHook();
  const telebirrMutation = useTelebirrAuthLoginHook();

  const continueWithTelebirr = () => {
    window.handleinitDataCallback = async (token) => {
      try {
        const user = await telebirrMutation.mutateAsync(token);
        handleUserData(user);
      } catch (error) {
        console.debug(error);
        setErrors({
          detail: error || "Something went wrong!",
        });
        setNotify(true);
      } finally {
        console.log("done");
      }
    };

    if (!window.consumerapp) {
      alert("This page is not open in app");
      return null;
    }

    const obj = JSON.stringify({
      functionName: "js_fun_h5GetAccessToken",
      params: {
        appid: "974631988558701",
        functionCallBackName: "handleinitDataCallback",
      },
    });

    window.consumerapp.evaluate(obj);
  };

  const handleUserData = (user) => {
    const { identifier, nickName } = user.biz_content;
    authMutate.mutate({ phone_number: identifier, first_name: nickName });
  };

  useEffect(() => {
    const handleSuccessfulAuth = async () => {
      if (authMutate.isSuccess) {
        const url = search ? search.split("=")[1] : "/";
        if (authMutate.data.user) {
          navigate(`/marketing-info/${urlGenerator(authMutate.data.user)}`, {
            state: authMutate.data,
          });
        } else {
          await login(authMutate.data);
          await loadUser();
          navigate(url);
        }
      }
    };

    handleSuccessfulAuth();
  }, [authMutate.isSuccess]);

  useEffect(() => {
    if (authMutate.isError) {
      setErrors({ detail: "Something went wrong!" });
      setNotify(true);
    }
  }, [authMutate.isError]);

  useEffect(() => {
    continueWithTelebirr();
  }, []);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          onClick={continueWithTelebirr}
          sx={{ textTransform: "inherit" }}
        >
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {t("Continue with")}
          </Typography>
          <CustomIcon style={{ height: 60, width: 100 }} />
        </Button>
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {t("OR")}
        </Typography>
      </Divider>

      <Alert
        open={notify}
        setOpen={() => setNotify(!notify)}
        message={errors.detail}
        severity="error"
      />
    </>
  );
}

export default connect(null, { loadUser, login: LOGIN })(AuthSocial);
