import React from 'react'
import { Icon } from "@iconify/react";
import { Fab, Slide, useScrollTrigger } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import ChatNotif from "../../components/Feedback/Notification/ChatNotif";
const StyledFab = styled(Fab)({
  margin: "0 auto",
  boxShadow: "0px 0px 0px 0px white inset",
});
const useStyles = makeStyles((theme) => ({
  appbar: {
    top: "auto",
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    width: "auto",
    right: "0",
    left: "0",
    margin: "0 auto",
    width: "100%",
    paddingTop: "2px",
    paddingBottom: "1px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    height: "auto",
    padding: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    height: "52px",
    minHeight: "49px",
  },
  button: {
    fontSize: 28,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecoration: "none",
    width: 70,
  },
  buttonText: {
    fontSize: "13px",
    lineHeight: "0.9",
    marginTop: "4px",
    marginBottom: "3px",
  },
}));

export default function MobileAppBar(props) {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <AppBar className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <Box
          className={classes.button}
          component={RouterLink}
          to={"/"}
          sx={{
            color:
              location.pathname === `/` ? "secondary.main" : "text.ternary",
          }}
        >
          <Icon width="23" height="23" icon="bx:home-smile" inline={true} />
          <Box
            sx={{
              color: location.pathname === `/` ? "secondary.main" : "grey.500",
            }}
            className={classes.buttonText}
          >
            {t("Home")}
          </Box>
        </Box>
        <Box
          className={classes.button}
          component={RouterLink}
          to={"/category"}
          sx={{
            color:
              location.pathname.includes("/search") ||
              location.pathname.includes("/category")
                ? "secondary.main"
                : "text.ternary",
          }}
        >
          <Icon width="23" height="23" icon="charm:search" inline={true} />
          <Box
            sx={{
              color:
                location.pathname.includes("/search") ||
                location.pathname.includes("/category")
                  ? "secondary.main"
                  : "grey.500",
            }}
            className={classes.buttonText}
          >
            {t("Search")}
          </Box>
        </Box>
        <Box
          className={classes.button}
          component={RouterLink}
          to={"/post-ad"}
          sx={{
            color: location.pathname.includes("/post-ad")
              ? "secondary.main"
              : "text.ternary",
          }}
        >
          <Icon icon="akar-icons:plus" />
          <Box
            sx={{
              color: location.pathname.includes("/post-ad")
                ? "secondary.main"
                : "grey.500",
            }}
            className={classes.buttonText}
          >
            {t("Post")}
          </Box>
        </Box>
        <Box
          className={classes.button}
          component={RouterLink}
          to={"/chat"}
          sx={{
            color: location.pathname.includes(`/chat`)
              ? "secondary.main"
              : "text.ternary",
          }}
        >
          <ChatNotif />
          <Box
            className={classes.buttonText}
            sx={{
              color: location.pathname.includes(`/chat`)
                ? "secondary.main"
                : "grey.500",
            }}
          >
            {t("Message")}
          </Box>
        </Box>
        <Box
          className={classes.button}
          component={RouterLink}
          to={"/profile"}
          sx={{
            color: location.pathname.includes(`/profile`)
              ? "secondary.main"
              : "text.ternary",
          }}
        >
          <Icon width="23" height="23" icon="akar-icons:person" inline={true} />
          <Box
            className={classes.buttonText}
            sx={{
              color: location.pathname.includes(`/profile`)
                ? "secondary.main"
                : "grey.500",
            }}
          >
            {t("Profile")}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
