import React from 'react'
import { Avatar, Badge, Box, Paper, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { fToNow } from "../../utils/formatTime";
import Scrollbar from "../Scrollbar";
const useStyles = makeStyles((theme) => ({
  paper: {
    height: 70,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textDecoration: "none",
    cursor: "pointer",
  },
  title: {
    width: "70%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "14px",
    fontSize: "12px",
  },
  category: {
    width: "50%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "14px",
    fontSize: "12px",
  },
  release_date: {
    width: "40%",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));
function ActivateNav({ data, setActive }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box component="nav" sx={{ minHeight: "545px" }}>
      <Scrollbar>
        {data.map((item) => (
          <Paper
            key={item.id}
            elevation={4}
            className={classes.paper}
            onClick={() => setActive(item.id)}
            to={`/product/activate/${item.id}`}
            component={RouterLink}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ width: "100%" }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar alt={item.owner.name} src={item.owner.logo} />
              </Badge>
              <div style={{ width: "100%" }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography
                    style={{
                      lineHeight: "14px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    variant="body2"
                    color="textSecondary"
                    mt={0}
                    mb={0}
                  >
                    {item.owner.name}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography
                    style={{ fontSize: "14px", lineHeight: "18px" }}
                    variant="subtitle1"
                    className={classes.title}
                    mb={0}
                  >
                    {item.title}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography
                    className={classes.category}
                    variant="body2"
                    color="textSecondary"
                    mt={0}
                    mb={0}
                  >
                    {t(item.category.name)}
                  </Typography>
                  <Typography
                    className={classes.release_date}
                    style={{ lineHeight: "14px", fontSize: "12px" }}
                    variant="body2"
                    textAlign="right"
                    color="textSecondary"
                    mt={0}
                    mb={0}
                  >
                    {fToNow(item.release_date)}
                  </Typography>
                </Stack>
              </div>
            </Stack>
          </Paper>
        ))}
      </Scrollbar>
    </Box>
  );
}
export default ActivateNav;
