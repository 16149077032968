import React from 'react'
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Catagory } from "../Catagory";
import CustomAutoComplete from "../Inputs/AutoComplete";
import useSearchBarHook from "./SearchBarHook";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
    marginRight: theme.spacing(2),
  },
}));

export default function SearchBar2(props) {
  const classes = useStyles();

  const {
    adSearchQuery,
    adSearchCategory,
    handleCatagoryChange,
    categoryList,
    categoryIsLoading,
    onTextChange,
    onSearch,
    searchAutoCompleteIsLoading,
    searchAutoCompleteData,
    searchIsLoading,
  } = useSearchBarHook(props);

  return (
    <Paper component="form" className={classes.root}>
      <Catagory
        category={adSearchCategory}
        categoryList={categoryList}
        loading={categoryIsLoading}
        handleCatagoryChange={handleCatagoryChange}
      />
      <Divider className={classes.divider} orientation="vertical" />

      <CustomAutoComplete
        placeholder="type something..."
        text={adSearchQuery}
        onTextChange={onTextChange}
        onSearch={onSearch}
        isLoading={searchAutoCompleteIsLoading}
        options={searchAutoCompleteData}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        color="primary"
        size="large"
        onClick={onSearch}
        disabled={searchIsLoading}
      >
        <SearchIcon fontSize="20px" />
      </IconButton>
    </Paper>
  );
}
