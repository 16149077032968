import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ValidateImagePixel } from "..";
import { RootContext } from "../../../../pages/ProductAd/AdForm";
import fileType from "../../../../utils/fileTypeChecker";
import TipOnScrollDialog from "./TipForTakePicture";
const useStyles = makeStyles(() => ({
  cover: {
    bottom: "0px",
    padding: "2px",
    width: "100%",
    left: "0px",
    position: "absolute",
    color: "rgb(255, 255, 255)",
    backgroundColor: "#FB8500",
    textAlign: "center",
  },
  remove: {
    fontSize: "1.125rem",
    top: "6px",
    padding: "2px",
    right: "6px",
    position: "absolute",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, 0.72)",
  },
  listImage: {
    padding: "0px",
    margin: "4px",
    width: "100%",
    height: "150px",
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
    display: "inline-flex",
    border: "1px solid rgba(145, 158, 171, 0.24)",
  },
  error: {
    color: "#FF4842",
    lineHeight: "15px",
    fontSize: "0.75rem",
    fontFamily: "Public Sans,sans-serif",
    fontWeight: "400",
    margin: "2px 14px",
  },
}));
const Input = styled("input")({
  display: "none",
});
function UploadImage({ max_image, ...props }) {
  const classes = useStyles();
  const [images, setImages] = useState([]);
  const { photos, setPhotos, errors, setErrors } = useContext(RootContext);
  function SelectCover(index) {
    let filesObj = [...photos];
    if (props.cover && filesObj[index].cover) filesObj[index].cover = false;
    else {
      for (const i in filesObj) filesObj[i].cover = false;
      filesObj[index].cover = true;
    }
    setPhotos(filesObj);
    setErrors((prev) => ({ ...prev, photos: null }));
  }
  function RemoveImage(image) {
    let filesObj = [...photos];
    filesObj = filesObj.filter((file) => file !== image);
    if (image.cover && filesObj.length > 0 && !props.cover)
      filesObj[0].cover = true;
    if (filesObj.length <= max_image)
      setErrors((prev) => ({ ...prev, photos: null }));
    setPhotos(filesObj);
  }
  useEffect(async () => {
    let filesObj = [...photos];
    let length = props.length ? props.length : max_image;
    filesObj = await ValidateImagePixel(
      filesObj,
      images,
      setErrors,
      max_image,
      length
    );
    setPhotos(filesObj);
  }, [images]);
  const handleChange = (files) => {
    let filesObj = [];
    Array.from(files).map((file) => {
      if (fileType(file) === "image") {
        filesObj.push({ img: file, cover: false, loading: true });
      } else {
        let errorFile = "Upload valid image file please.";
        setErrors((prev) => ({ ...prev, photos: errorFile }));
      }
    });
    let index = photos.findIndex((item) => item.cover);
    if (index === -1 && filesObj.length > 0 && !props.cover)
      filesObj[0].cover = true;
    filesObj.map((file) => setPhotos((photo) => [...photo, file]));
    setImages(filesObj);
    Array.from(files).map(
      (file) => URL.revokeObjectURL(file) // avoid memory leak
    );
  };
  useEffect(() => {
    if (!props.cover && photos.length > 0) {
      let index = photos.findIndex((item) => item.cover);
      if (index === -1) {
        let filesObj = [...photos];
        filesObj[0].cover = true;
        setPhotos([...filesObj]);
      }
    }
  }, [props.cover]);
  const { t } = useTranslation();
  return (
    <Grid item sm={12} width="100%">
      <TipOnScrollDialog />
      <List
        sx={{
          listStyle: "none",
          padding: "0px",
          position: "relative",
          display: "grid",
          gridTemplateColumns: "repeat(2,  minmax(0, 1fr))",
          gridAutoRows: "1fr",
          columnGap: "10px",
          rowGap: "10px",
        }}
      >
        {photos.map((file, index) => (
          <Box key={index} className={classes.listImage}>
            <Box
              component={LazyLoadImage}
              src={URL.createObjectURL(file.img)}
              onClick={() => SelectCover(index, "cover")}
              effect="blur"
              wrapperClassName="wrapper"
              alt="preview"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            {file.cover && (
              <Typography variant="caption" className={classes.cover}>
                {t("Cover image")}
              </Typography>
            )}
            <IconButton
              onClick={() => RemoveImage(file)}
              className={classes.remove}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </Box>
        ))}
      </List>
      <Box className={classes.error}>{errors.photos}</Box>
      <Box
        component="label"
        htmlFor="upload-button-file"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          margin: "0 0 24px",
        }}
      >
        <Input
          multiple
          type="file"
          name="image"
          accept="image/*"
          id="upload-button-file"
          onChange={(e) => handleChange(e.target.files)}
        />
        <Button
          component="span"
          color="secondary"
          variant="outlined"
          startIcon={<PhotoCamera />}
          sx={{ width: "100%", py: 2, fontSize: 18 }}
        >
          {t("Upload Photos")}
        </Button>
      </Box>
    </Grid>
  );
}
export default memo(UploadImage);
