import React from 'react'
import { Autocomplete, Box, ListItem, ListItemText } from "@mui/material";
import Grid from "@mui/material/Grid";
import { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { disableDependency } from "..";
import { RootContext } from "../../../../pages/ProductAd/AdForm";
import { ChoiceName, CONDITION_EXCLUDE } from "../../../../utils/Post";
import { message } from "../../../Account/Constants";
import CustomizedTextField from "../../../TextField/TextField";
import { dependencyValueCheck } from "../FieldValidate";
function ChoiceField({ other }) {
  const { table, slug } = other;
  const { values, errors, setErrors, setValues } = useContext(RootContext);
  const handleBlur = (e) => {
    if (e.target.required && !e.target.value) {
      setErrors((prev) => ({
        ...prev,
        [e.target.name]: message.VALUE_ERROR,
      }));
    }
  };
  const handleChange = (e, newValue, name) => {
    let value = newValue ? newValue.value : "";
    let error = dependencyValueCheck(name, value, values);
    setValues((prev) => ({ ...prev, ...error.values, [name]: value }));
    setErrors((prev) => ({ ...prev, ...error.errors }));
  };
  const { t } = useTranslation();
  return (
    table in ChoiceName &&
    !CONDITION_EXCLUDE.includes(slug) &&
    ChoiceName[table].map(
      (choice) =>
        !(choice.dis && disableDependency(choice.dis, values)) && (
          <Grid item xs={12} sm={6} key={choice.key}>
            <Autocomplete
              fullWidth
              disablePortal
              options={choice.options}
              value={choice.name in values ? t(values[choice.name]) : ""}
              onChange={(e, v) => handleChange(e, v, choice.name)}
              renderInput={(params) => (
                <CustomizedTextField
                  {...params}
                  name={choice.name}
                  label={
                    "required" in choice
                      ? t(choice.label) + t("(optional)")
                      : t(choice.label)
                  }
                  onBlur={handleBlur}
                  error={Boolean(errors[choice.name])}
                  helperText={t(errors[choice.name])}
                  required={!("required" in choice)}
                  disabled={choice.dis && disableDependency(choice.dis, values)}
                />
              )}
              renderOption={(props, option) => (
                <ListItem {...props} sx={{ bgcolor: "rev_grey.200" }}>
                  {option.color && (
                    <Box
                      sx={{
                        height: 30,
                        width: 30,
                        borderRadius: "50%",
                        backgroundColor: String(option.color),
                        mr: 2,
                        boxShadow:
                          option.color === "Other"
                            ? "none"
                            : "rgba(4, 17, 29, 0.25) 0px 0px 8px 0px",
                      }}
                    />
                  )}
                  <ListItemText>{t(option.label)}</ListItemText>
                </ListItem>
              )}
            />
          </Grid>
        )
    )
  );
}
export default memo(ChoiceField);
