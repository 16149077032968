import React from "react";
import { IconButton, Stack, SvgIcon } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as logoSvg } from "../../config/logo/fs1.svg";
import { logout } from "../../redux/actions/auth";
import { LanguageSelects } from "../Button";
import { SearchBar2 } from "../SearchBar/";
import Authentication from "./Authentication";
import PostAdButton from "./PostAdButton";
import LanguageIcon from "@mui/icons-material/Language";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    height: "58px",
    boxShadow: "rgba(4, 17, 29, 0.25) 0px 0px 2px 0px",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {},
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    textDecoration: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchButton: {
    border: 0,
    borderRadius: 113,
    display: "none",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  button: {
    fontSize: "14px",
    color: "#fff",
    margin: theme.spacing(0, 2),
    textDecoration: "none",
    transition: "all 0.3s ease",
    textTransform: "none",
    "&:hover": {
      color: " #fff",
    },
  },
}));
function AppBar2({ logout, isAuthenticated, location, search }) {
  const classes = useStyles();
  const [open, setOpen] = useState()
  const [, setDrawerActive] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (window.innerWidth <= 800) {
      setDrawerActive(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 800) {
        setDrawerActive(true);
      } else {
        setDrawerActive(false);
      }
    });
  }, []);
  return (
    <>
      <div className={classes.grow}>
        <AppBar position="static" className={classes.root}>
          <Toolbar sx={{ height: "100%", p: 1 }}>
            {search ? (
              <SearchBar2 />
            ) : (
              <>
                <SvgIcon
                  sx={{
                    fontSize: "98px",
                    height: "35px",
                    width: "127px",
                    ml: 1,
                    color: "primary.main",
                  }}
                  component={logoSvg}
                  inheritViewBox
                />
                <div className={classes.grow} />
                <Hidden smDown>
                  <div className={classes.grow} />
                  <LanguageSelects />
                </Hidden>
                <Stack
                  sx={{ ml: 1.5, p: 0, color: "#000" }}
                  flexDirection={"row"}
                  aria-label="language-button"
                >
                  <LanguageIcon sx={{ mr: 2 }} />
                  <LanguageSelects setOpen={setOpen} />
                </Stack>
                {/* <Authentication logout={logout} navigate={navigate} /> */}
                <Hidden smDown>
                  <PostAdButton navigate={navigate} />
                </Hidden>
              </>
            )}
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { logout })(AppBar2);
