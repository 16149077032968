import React from 'react'
import arrowLeft from "@iconify/icons-carbon/arrow-left";
import { Icon } from "@iconify/react";
import { Box, Container, IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../config/logo/logo_footer.png";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: "68px",
    boxShadow: "rgba(4, 17, 29, 0.25) 0px 0px 2px 0px",
  },
  button: {
    fontSize: "14px",
    color: "#fff",
    margin: theme.spacing(3, 3, 2, 3),
    textDecoration: "none",
    transition: "all 0.3s ease",
    textTransform: "none",
    "&:hover": {
      color: " #fff",
    },
  },
  title: {
    flexGrow: 1,
    color: theme.palette.primary.main,
    textAlign: "center",
  },
}));
export function AppBarWithBack({ title, goHome }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleBack = () => {
    if (goHome) navigate("/");
    else navigate(-1);
  };
  const { t } = useTranslation();
  return (
    <AppBar className={classes.root} position="static">
      <Toolbar sx={{ height: "100%" }}>
        <Box
          component={IconButton}
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "25px",
            color: "primary.main",
            color: "text.primary",
          }}
          size="small"
          onClick={handleBack}
        >
          <Icon icon={arrowLeft} />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {title ? (
            <Typography
              sx={{ color: "text.primary" }}
              fontFamily='proxima-nova, ProximaNova, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
              fontWeight="600"
              fontSize="38px"
              variant="h4"
            >
              {t(title)}
            </Typography>
          ) : (
            <Container
              sx={{
                length: 8,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={logo} height="38" alt="FamilySooq" />
            </Container>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
