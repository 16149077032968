import React from 'react'
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { memo, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useImageRange } from "../../../../hooks";
import {
  allowImageNull,
  RootContext,
} from "../../../../pages/ProductAd/AdForm";
import Address from "./Address";
import CheckBoxField from "./CheckBoxField";
import ChoiceField from "./ChoiceField";
import InputField from "./InputField";
import MultiChoiceField from "./MultiChoiceField";
import TextAreaField from "./TextAreaField";
import UploadImage from "./UploadImage";
const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "flex-end",
    marginTop: theme.spacing(1.5),
  },
  subutton: {
    width: "50%",
    fontSize: theme.spacing(2.5),
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));
function AdDetailInfo({ other, update, setConfig }) {
  const classes = useStyles();
  const { values } = useContext(RootContext);
  const { data, isSuccess } = useImageRange(values.category);
  useEffect(() => {
    if (isSuccess) setConfig(data);
  }, [isSuccess, data]);
  const { t } = useTranslation();

  const getCatName = (slug) => {
    let atrs = slug
      .split("-")
      .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
      .join(" ");
    return t(atrs);
  };

  return (
    <>
      <Grid container spacing={2} mt={1}>
        <InputField table="common" />
        {allowImageNull.includes(other.table) ? (
          <InputField table="priceoptional" />
        ) : (
          <InputField table="pricerequired" />
        )}
        <ChoiceField other={other} />
        <InputField table={other.table} />
        <MultiChoiceField table={other.table} />
        <TextAreaField table={other.table} />
      </Grid>
      <CheckBoxField table={other.table} />
      <Grid container>
        <Address />
        {!(update || allowImageNull.includes(other.table)) && data && (
          <>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontSize: "14px", m: "16px 0 8px 0" }}
            >
              {t("image.helperText1", {
                min: data.min_image,
                max: data.max_image,
                category: getCatName(other.slug),
              })}
              <br />
              {t("image.helperText2")}
            </Typography>
            <UploadImage max_image={data.max_image} />
          </>
        )}
      </Grid>
      <Box className={classes.button}>
        <Button
          fullWidth
          className={classes.subutton}
          variant="contained"
          type="submit"
          color="secondary"
        >
          {update ? t("Update") : t("Post")}
        </Button>
      </Box>
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
export default memo(AdDetailInfo);
