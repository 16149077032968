import React from 'react'
export default function CustomLoading() {
  return (
    <div className="snippet" data-title=".dot-stretching">
      <div className="stage">
        <div className="dot-stretching"></div>
      </div>
    </div>
  );
}
