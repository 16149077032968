import React from 'react'
import ClearIcon from "@mui/icons-material/Clear";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { RootContext } from "../../../../pages/ProductAd/AdForm";
import { MultiSelect } from "../../../../utils/Post";
import { message } from "../../../Account/Constants";
const useStyles = makeStyles(() => ({
  error: {
    color: "#FF4842",
    lineHeight: "15px",
    fontSize: "0.75rem",
    fontFamily: "Public Sans,sans-serif",
    fontWeight: "400",
    margin: "2px 14px",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function MultiChoiceField({ table }) {
  const theme = useTheme();
  const classes = useStyles();
  const { values, errors, setErrors, setValues } = useContext(RootContext);
  const handleBlur = (e) => {
    if (e.target.required && !e.target.value) {
      setErrors((prev) => ({
        ...prev,
        [e.target.name]: message.VALUE_ERROR,
      }));
    }
  };
  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setValues((prev) => ({
      ...prev,
      [e.target.name]: typeof value === "string" ? value.split(",") : value,
    }));
    setErrors((prev) => ({ ...prev, [e.target.name]: null }));
  };
  const handleClear = (name) => {
    setValues((prev) => ({ ...prev, [name]: [] }));
    setErrors((prev) => ({ ...prev, [name]: null }));
  };
  const { t } = useTranslation();
  return (
    table in MultiSelect &&
    MultiSelect[table].map((select) => (
      <Grid item xs={12} sm={6} key={select.name}>
        <FormControl sx={{ width: "100%" }}>
          {(values[select.name] && values[select.name].length) > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                cursor: "pointer",
                right: 25,
                top: "calc(50% - 12px)",
                zIndex: 1,
                bgcolor: "#00000007",
                borderRadius: "50%",
                height: "25px",
                width: "25px",
              }}
              onClick={() => handleClear(select.name)}
            >
              <ClearIcon />
            </Box>
          )}
          <InputLabel>{t(select.label)}</InputLabel>
          <Select
            multiple
            name={select.name}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(errors[select.name])}
            helperText={t(errors[select.name])}
            value={values[select.name] ? values[select.name] : []}
            input={<OutlinedInput label={t(select.label)} />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={t(value)} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {select.options.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(
                  name,
                  select.name in values && values[select.name]
                    ? values[select.name]
                    : [],
                  theme
                )}
              >
                {t(name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box className={classes.error}>{t(errors[select.name])}</Box>
      </Grid>
    ))
  );
}
export default memo(MultiChoiceField);
