import React from 'react'
import { Box, CircularProgress, Container } from "@mui/material";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { googleAuthenticate } from "../../redux/actions/auth";
import Alert from "../Alert/Alert";
import MarketingInfo from "./MarketingInfo";
import { checkError } from "./ValidateField";
function Google({ googleAuthenticate, user }) {
  let location = useLocation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [notify, setNotify] = useState(false);
  useEffect(() => {
    async function googleAuth() {
      const values = queryString.parse(location.search);
      const state = values.state ? values.state : null;
      const code = values.code ? values.code : null;
      if (state && code) {
        const ERROR = await googleAuthenticate(state, code);
        if (ERROR.created) errors.created = true;
        else {
          checkError(ERROR, errors, values);
          setErrors((err) => ({ ...err }));
          if ("error" in errors) {
            setNotify(true);
            setInterval(() => navigate("/signup"), 2000);
          }
        }
      }
    }
    googleAuth();
  }, [googleAuthenticate, location.search]);

  if (user) {
    if (errors.created)
      return (
        <Container
          component="main"
          maxWidth="xs"
          sx={{ display: "flex", flex: 1 }}
        >
          <MarketingInfo user={user.id} />
        </Container>
      );
    else return <Navigate to="/" />;
  }
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          p: 5,
        }}
      >
        <CircularProgress variant="indeterminate" />
      </Box>
      <Alert
        open={notify}
        setOpen={() => setNotify(!notify)}
        message={errors.error}
        severity="error"
      />
    </>
  );
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, { googleAuthenticate })(Google);
