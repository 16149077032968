import React from 'react'
import ClockIcon from "@iconify/icons-eva/clock-outline";
import { Icon } from "@iconify/react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Box,
  Grid,
  Hidden,
  IconButton,
  Link,
  Skeleton,
  Stack,
} from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { fNumber } from "../../utils/formatNumber";
import { fToNow } from "../../utils/formatTime";
import Label from "../Label";
import AttributeConfig from "../products1/AttributeConfigLongCards";
import Favorite from "../products1/Favorite";
import ImagePlaceHolder from "./ImagePlaceHolder";
import ShowPhoneNumber from "./ShowPhoneNumber";
import withLoginRequired from "./withLoginRequired";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.6),
    paddingRight: 0,
    borderRadius: 5,
    borderBottom: `thin solid ${theme.palette.divider}`,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 1,
    },
  },
  cover: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    position: "relative",
  },
  body: {
    minHeight: 50,
  },
  price: {
    lineHeight: "2px",
    float: "right",
    textAlign: "end",
  },
  content: {
    display: "flex",
    height: "100%",
    marginLeft: theme.spacing(1.5),
  },
  contentTitle: {
    marginBottom: theme.spacing(0.4),
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "left",
      flexDirection: "row",
    },
    [theme.breakpoints.up("sm")]: {
      float: "right",
      flexWrap: "noWrap",
    },
  },
}));
const WithAuthFav = withLoginRequired(IconButton);
export default function AdCardMid({
  isLoading,
  data,
  filter_category,
  includeFav,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Card className={classes.root}>
      <Link
        component={RouterLink}
        to={data ? `/detail/${data.id}` : ""}
        sx={{ textDecoration: "none", width: "100%" }}
      >
        <Grid container>
          <Grid item xs={5} sm={4} md={4} lg={4}>
            {isLoading ? (
              <Skeleton
                variant="rect"
                animation="wave"
                sx={{ pb: 2, height: "100%" }}
              />
            ) : (
              <div className={classes.cover}>
                <Box sx={{ position: "relative", height: 100 }}>
                  <ImagePlaceHolder
                    src={data.image}
                    alt={data.title}
                    style={{ borderRadius: "10px" }}
                  />
                  {data.is_featured && (
                    <Label
                      variant="filled"
                      color="secondary"
                      sx={{
                        zIndex: 9,
                        top: 5,
                        left: 5,
                        position: "absolute",
                        fontSize: "10px",
                        fontWeight: 200,
                      }}
                    >
                      {t("Featured")}
                    </Label>
                  )}
                  {includeFav && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: "white",
                        fontSize: "12px",
                        borderTopRightRadius: "5px",
                        borderBottomLeftRadius: "10px",
                        py: 0.3,
                        px: 0.7,
                      }}
                    >
                      <Favorite
                        id={data.id}
                        sx={{
                          bgcolor: "rgba(0, 0, 0, 0.54)",
                          color: "common.white",
                          ml: 1,
                          p: 1,
                        }}
                        css={{
                          fontSize: "12px",
                        }}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      bgcolor: "rgba(0, 0, 0, 0.54)",
                      color: "white",
                      fontSize: "12px",
                      borderTopRightRadius: "5px",
                      borderBottomLeftRadius: "10px",
                      py: 0.3,
                      px: 0.7,
                    }}
                  >
                    {data.photo_count}
                  </Box>
                </Box>
                <Box
                  sx={{
                    color: "text.primary",
                    fontSize: "18px",
                    textAlign: "center",
                    mt: 0.6,
                  }}
                >
                  <Trans i18nKey="ad_price">
                    {" "}
                    <Box
                      component="span"
                      variant="caption"
                      fontSize="11px"
                      sx={{ color: "secondary.main", mr: 0.5 }}
                    >
                      ETB
                    </Box>
                    {{ price: fNumber(data.price) }}
                  </Trans>
                </Box>
              </div>
            )}
          </Grid>
          <Grid item xs={7} sm={8} md={8} lg={8} xl={8}>
            <Box sx={{ height: "100%" }} className={classes.content}>
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Grid container>
                  <Grid item xs={12} sm={7} md={12} lg={12} xl={12}>
                    {isLoading ? (
                      <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                          paddingRight: "5px",
                        }}
                      >
                        <Skeleton variant="rect" animation="wave" />
                      </Typography>
                    ) : (
                      data.title && (
                        <Typography
                          sx={{
                            "-moz-box-flex": 1,
                            flexGrow: 1,
                            maxHeight: "60px",
                            "-webkit-line-clamp": 2,
                            "-moz-box-orient": "vertical",
                            textOverflow: "ellipsis",
                            wordBreak: "break-word",
                            display: "-webkit-box",
                            hyphens: "auto",
                            lineHeight: "20px",
                            mb: 1,
                            fontSize: "16px",
                            color: "text.primary",
                          }}
                        >
                          {data.title.constructor === Array
                            ? data.title.map((tit, index) => (
                                <Box
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {tit}
                                  {index !== data.title.length - 1 && (
                                    <Box
                                      sx={{
                                        width: "6px",
                                        height: "6px",
                                        borderRadius: "50%",
                                        backgroundColor: "#626465",
                                        marginX: "4px",
                                      }}
                                    />
                                  )}
                                </Box>
                              ))
                            : data.title
                                .split(">")
                                .map((x) => t(x))
                                .join(", ")}
                        </Typography>
                      )
                    )}
                  </Grid>
                  <Grid item xs={12} sm={5} md={4} lg={4} xl={4}></Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {isLoading ? (
                      <Typography
                        variant="h4"
                        style={{
                          paddingRight: "5px",
                        }}
                      >
                        <Skeleton variant="rect" animation="wave" height={70} />
                      </Typography>
                    ) : (
                      <Box sx={{ display: "flex" }}>
                        {filter_category && data && (
                          <Grid container>
                            {AttributeConfig[filter_category].map(
                              (info, index) =>
                                data[info.value] && (
                                  <Grid
                                    item
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      "& > :not(style)": {
                                        whiteSpace: "nowrap",
                                      },
                                    }}
                                  >
                                    <Box
                                      component={Icon}
                                      icon={info.icon}
                                      sx={{
                                        width: "16px",
                                        height: "15px",
                                        mr: 1,
                                        color: "grey.500",
                                      }}
                                    />
                                    <Typography
                                      sx={{ color: "grey.500" }}
                                      fontSize="12px"
                                      variant="body2"
                                    >
                                      {data && info.value === "finished"
                                        ? t("Finished")
                                        : t(data[info.value]) +
                                          " " +
                                          t(info.unit)}
                                    </Typography>
                                  </Grid>
                                )
                            )}
                          </Grid>
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Hidden smDown>
                  {isLoading ? (
                    <Typography
                      style={{
                        paddingRight: "5px",
                        paddingTop: "4px",
                      }}
                    >
                      <Skeleton variant="rect" animation="wave" />
                    </Typography>
                  ) : (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        sx={{ diaplay: "flex", alignItems: "center" }}
                        variant="caption"
                        color="textSecondary"
                      >
                        <LocationOnIcon fontSize="inherit" sx={{ mr: 1 }} />
                        {data.location
                          .split(", ")
                          .map((x, i) => (i > 0 ? ", " : "") + t(x))}
                      </Typography>
                      {data && data.release_date && (
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Box
                            component={Icon}
                            icon={ClockIcon}
                            sx={{ width: 16, height: 16, mr: 0.5 }}
                          />
                          <Typography color="textSecondary" variant="caption">
                            {fToNow(data.release_date)}
                          </Typography>
                        </Box>
                      )}
                      <Box sx={{ display: "flex" }}>
                        <ShowPhoneNumber
                          id={data.id}
                          phoneNumber={data.phone_number}
                        />
                      </Box>
                    </Stack>
                  )}
                </Hidden>
                <Hidden smUp>
                  {isLoading ? (
                    <Typography sx={{ p: 0.5 }}>
                      <Skeleton variant="rect" animation="wave" height={18} />
                    </Typography>
                  ) : (
                    <Stack>
                      {data && data.release_date && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            color: "text.disabled",
                            mr: 1.5,
                            mb: 0.4,
                          }}
                        >
                          <Box
                            component={Icon}
                            icon={ClockIcon}
                            sx={{ width: 16, height: 16, mr: 0.5 }}
                          />
                          <Typography color="textSecondary" variant="caption">
                            {fToNow(data.release_date)}
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  )}
                </Hidden>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}></Grid>
        </Grid>
      </Link>
    </Card>
  );
}
