import React from "react";
import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
export function PostHeader({ page, steps, update }) {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography component="h1" variant="h4" align="center">
        {update ? t("Update Your Ad") : t("Post Your Ad")}
      </Typography>
      <Stepper alternativeLabel activeStep={page - 1}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
