import React from "react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AppBar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Slide,
  Stack,
  SvgIcon,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as logoSvg } from "../../config/logo/fs1.svg";
import { close_login_dialog, load_user, login } from "../../redux/actions/auth";
import { isEmpty } from "../../utils/validation";
import AutoSocial from "./AuthSocial";
import { message } from "./Constants";
import { checkEmpty } from "./ValidateField";
import DialogPage from "./DialogPage";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Login({ login, load_user, dCA, ...props }) {
  const { close_login_dialog, openLoginDialog } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ email: "", password: "" });
  const handleClose = () => {
    close_login_dialog();
  };
  const handleBlure = (e) => {
    if (e.target.value === "")
      setErrors((prev) => ({ ...prev, [e.target.name]: message.VALUE_ERROR }));
  };
  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors((prev) => ({ ...prev, [e.target.name]: null }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = checkEmpty(e);
    setErrors((prev) => ({ ...prev, ...error, detail: null }));
    setSubmitted(true);
  };
  useEffect(async () => {
    if (submitted && isEmpty(errors)) {
      setOpen(true);
      const response = await login(values);
      if (response.two_factor_auth) {
        close_login_dialog();
        navigate(`/two-step-verification`, { state: response });
      } else if (isEmpty(response)) {
        load_user();
        dCA && dCA();
      } else {
        if (response.otp) {
          close_login_dialog();
          navigate(`/activate/${Number(response.otp)}`);
        }
        if (
          response.password &&
          response.password[0] === "This field may not be blank."
        )
          response.password[0] = "Invalid password.";
        setErrors(response);
      }
    }
    setSubmitted(false);
  }, [submitted]);
  return (
    <Dialog open={openLoginDialog} fullScreen TransitionComponent={Transition}>
      <DialogPage open={open} setOpen={setOpen} />
      <AppBar sx={{ position: "relative", bgcolor: "background.paper" }}>
        <Toolbar>
          <Container
            maxWidth="xs"
            sx={{
              my: 3,
              length: 190,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SvgIcon
              sx={{
                fontSize: "98px",
                height: "25px",
                width: "177px",
                ml: 1,
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#fff" : "primary.main",
              }}
              component={logoSvg}
              inheritViewBox
            />
          </Container>
          <IconButton edge="start" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Backdrop
        sx={{
          color: "#fff",
          position: "absolute",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={submitted}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>
        <Typography
          mt={6}
          component="h1"
          variant="h2"
          sx={{ textAlign: "center" }}
        >
          {t("Sign in with")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" height="100%" width="100%">
          <Box width="100%">
            <AutoSocial />
            {errors.detail && (
              <Alert severity="error">{t(errors.detail)}</Alert>
            )}
            <form onSubmit={handleSubmit}>
              <Stack spacing={3} mb={3} mt={1}>
                <TextField
                  fullWidth
                  name="email"
                  label={t("Enter email or phone")}
                  onChange={handleChange}
                  onBlur={handleBlure}
                  error={Boolean(errors.email)}
                  helperText={t(errors.email)}
                />
                <TextField
                  fullWidth
                  name="password"
                  label={t("Password")}
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  onBlur={handleBlure}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword((show) => !show)}
                          edge="end"
                        >
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.password)}
                  helperText={t(errors.password)}
                />
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                {t("Login")}
              </LoadingButton>
            </form>
            <Stack direction="row" justifyContent="center" sx={{ my: 1 }}>
              <Link
                component="button"
                onClick={() => {
                  close_login_dialog();
                  navigate("/password/reset");
                }}
                variant="subtitle2"
                underline="none"
                color="secondary"
              >
                {t("Forgot password?")}
              </Link>
            </Stack>
            <Grid>
              <Divider />
            </Grid>
            <Grid container>
              <Grid item>
                <Box pt={3}>
                  <Box component="div" display="inline">
                    {t("Don't have an account?")}
                  </Box>
                  <Box pl={1} component="div" display="inline">
                    <Link
                      component="button"
                      onClick={() => {
                        close_login_dialog();
                        navigate("/signup");
                      }}
                      variant="subtitle2"
                      color="secondary"
                      underline="none"
                    >
                      {t("Signup")}
                    </Link>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("Cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => ({
  openLoginDialog: state.auth.openLoginDialog,
  dCA: state.auth.dCA,
});
export default connect(mapStateToProps, {
  login,
  load_user,
  close_login_dialog,
})(Login);
