
export const handlePaymentWithTelebirr = async (rawRequest, callback) => {
    try {
        window.handleinitDataCallback = function () {
            window.location.href = window.location.origin;
        };
        
        if (!rawRequest) return;
        const obj = JSON.stringify({
            functionName: "js_fun_start_pay",
            params: {
                rawRequest: rawRequest.trim().replaceAll(/["-]/g,""),
                functionCallBackName: "handleinitDataCallback",
            },
        });

        if (!window.consumerapp) {
            console.log("this page is not open in app");
        }

        window.consumerapp.evaluate(obj);
        if (callback && typeof callback === "function") {
            callback();
        }
    } catch (error) {
        console.log("error occur", error);
    }
    finally {
        // loading.hide();
    }
};
