import React from 'react'
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootContext } from "../../../../pages/ProductAd/AdForm";
import { message } from "../../../Account/Constants";
function Address() {
  const [label, setLabel] = useState({});
  const { address, values, errors, setErrors, setValues } =
    useContext(RootContext);
  const CHOICE = [
    { label: "Region/city", name: "region" },
    { label: "Zone/subcity", name: "zone" },
    { label: "Wereda", name: "location" },
  ];
  const handleBlur = (e) => {
    if (e.target.required && !e.target.value) {
      setErrors((prev) => ({
        ...prev,
        [e.target.name]: message.VALUE_ERROR,
      }));
    }
  };
  const handleChange = (e, newValue, name) => {
    const id = newValue ? newValue.value : "";
    let updateValue = {};
    let updateLabel = {};
    let error = {};
    if (name === "region") {
      if (id) {
        address.zone = address.region.find((item) => item.id === id).subcity;
      } else address.zone = [];
      if (address.location) address.location = [];
      updateValue.zone = "";
      updateValue.location = "";
      updateLabel.zone = "";
      updateLabel.location = "";
      error.zone = null;
      error.location = null;
    } else if (name === "zone") {
      if (id) {
        address.location = address.zone.find((item) => item.id === id).wereda;
      } else address.location = [];
      updateValue.location = "";
      updateLabel.location = "";
      error.location = null;
      if (address.location.length === 1) {
        updateValue.location = address.location[0].id;
        updateLabel.location = address.location[0].name;
      }
    }
    setLabel((prev) => ({
      ...prev,
      ...updateLabel,
      [name]: newValue ? newValue.label : "",
    }));
    setValues((prev) => ({ ...prev, ...updateValue, [name]: id }));
    setErrors((prev) => ({ ...prev, ...error, [name]: null }));
  };
  const getOptions = (data) => {
    let options = [];
    data.forEach((option) => {
      options = [...options, { label: option.name, value: option.id }];
    });
    return options;
  };
  useEffect(() => {
    if (address.region && values.region) {
      const region = address.region.find((item) => item.id === values.region);
      const zone = address.zone.find((item) => item.id === values.zone);
      const location = address.location.find(
        (item) => item.id === values.location
      );
      if (region && zone && location) {
        setLabel({
          region: region.name,
          zone: zone.name,
          location: location.name,
        });
      }
    }
  }, [address]);
  const { t } = useTranslation();
  return CHOICE.map((item) => (
    <Grid item xs={12} key={item.name} mt={2}>
      <Autocomplete
        fullWidth
        autoSelect
        disablePortal
        value={item.name in label ? t(label[item.name]) : ""}
        disabled={!(item.name in address) || address[item.name].length === 0}
        options={item.name in address ? getOptions(address[item.name]) : []}
        onChange={(e, v) => handleChange(e, v, item.name)}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 }, textAlign: "left" }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/et.png`}
              srcSet={`https://flagcdn.com/w40/et.png 2x`}
              alt=""
            />
            {t(option.label)}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            name={item.name}
            label={t(item.label)}
            onBlur={handleBlur}
            helperText={t(errors[item.name])}
            error={Boolean(errors[item.name])}
          />
        )}
      />
    </Grid>
  ));
}
export default memo(Address);
