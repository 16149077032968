import React from "react";
import warningOutlined from "@iconify/icons-ant-design/warning-outlined";
import { Icon } from "@iconify/react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
function withLoading(Component) {
  return function WihLoadingComponent({ isLoading, isError, ...props }) {
    const { t } = useTranslation();
    if (isLoading)
      return (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            p: 5,
          }}
          {...props}
        >
          <CircularProgress variant="indeterminate" />
        </Box>
      );
    if (isError)
      return (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            p: 5,
          }}
          {...props}
        >
          <Typography sx={{ display: "flex", alignItems: "center", mr: 2 }}>
            <Icon
              icon={warningOutlined}
              style={{ marginRight: "8px", color: "orange" }}
            />
            {t("Something went wrong")}
          </Typography>
        </Box>
      );
    return <Component {...props} />;
  };
}
export default withLoading;
