import React from 'react'
import debounce from "lodash.debounce";
import queryString from "query-string";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchAutoComplete } from "../../hooks";
import { useThirdLevelCatagories } from "../../hooks/catagory";
export default function useSearchBar2() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const categoryIsLoading = false;
  const [adSearchQuery, setAdSearchQuery] = useState(
    values.query !== undefined ? values.query : ""
  );
  const [adSearchCategory, setAdSearchCategory] = useState({
    name: values.category ? values.category : "All Categories",
    url: values.url ? values.url : "all",
    id: values.id ? values.id : 0,
    category: values.category ? values.category : "all",
  });
  const { isSuccess, data: categoryList } = useThirdLevelCatagories();
  const {
    isFetching: searchAutoCompleteIsLoading,
    data: searchAutoCompleteData,
    refetch: refetchSearchAutoComplete,
  } = useSearchAutoComplete({
    query: adSearchQuery,
    category: adSearchCategory.category,
  });
  const handleTextChange = (event, value) => {
    setAdSearchQuery(value);
  };
  const onTextChange = useMemo(
    () => debounce(refetchSearchAutoComplete, 500),
    []
  );
  useEffect(() => {
    return () => {
      onTextChange.cancel();
    };
  }, []);
  useEffect(() => {
    if (adSearchQuery.length > 2) onTextChange();
  }, [adSearchQuery]);
  const onSearch = (event, value) => {
    if (event !== undefined) event.preventDefault();
    if (adSearchQuery === "" || value === null) return;
    if (value) {
      navigate(`/search?query=${value.value}&category=${value.slug}`);
    } else {
      navigate(
        `/search?query=${adSearchQuery}&category=${adSearchCategory.category}`
      );
    }
  };
  const handleCatagoryChange = (value) => {
    setAdSearchCategory({
      category: value.slug ? value.slug : "all",
      name: value.name ? value.name : "All Categories",
      url: value.url,
      id: value.id,
    });
  };
  return (
    isSuccess && {
      adSearchQuery,
      adSearchCategory,
      setAdSearchQuery,
      setAdSearchCategory,
      categoryList,
      categoryIsLoading,
      handleCatagoryChange,
      onTextChange: handleTextChange,
      onSearch,
      searchAutoCompleteData,
      searchAutoCompleteIsLoading,
    }
  );
}
