import React from 'react'
import logoutIcon from "@iconify/icons-carbon/logout";
import favoriteOutline from "@iconify/icons-eva/heart-outline";
import menuOutline from "@iconify/icons-eva/menu-outline";
import { Icon } from "@iconify/react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import LanguageIcon from "@mui/icons-material/Language";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItem,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { logout } from "../../redux/actions/auth";
import WebSocketInstance from "../../services/notificationWebSocket";
import { LanguageSelects } from "../Button";
import withLoginRequired from "../Feedback/withLoginRequired";
import { ThemeMode } from "../Inputs/ThemeMode";
import MenuPopover from "../MenuPopover";
const sidebarConfig = [
  {
    label: "My Ads",
    linkTo: "profile/myads",
    icon: menuOutline,
  },
  {
    label: "My Favourites",
    linkTo: "/profile/favourites",
    icon: favoriteOutline,
  },
];
function AccountPopover({ user, logout, isAuthenticated }) {
  const theme = useTheme();
  const [anchorAccRef, setAccAnchorRef] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = (event) => {
    setAccAnchorRef(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAccAnchorRef(null);
  };
  const handleLogout = () => {
    logout();
    navigate("/");
    WebSocketInstance.socketRef && WebSocketInstance.socketRef.close();
    setOpen(false);
  };
  const { t } = useTranslation();
  return (
    <>
      <IconButton
        sx={{ ml: 1.5, p: 0 }}
        ref={anchorAccRef}
        onClick={(event) =>
          isAuthenticated ? handleOpen(event) : navigate("/signin")
        }
        aria-label="profile-button"
      >
        <Avatar src={user && user.picture} />
      </IconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorAccRef && anchorAccRef}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user && user.first_name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user && user.email}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        {sidebarConfig.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />
            {t(option.label)}
          </MenuItem>
        ))}
        <MenuItem sx={{ typography: "body2", py: 1, px: 2.5 }}>
          <LanguageIcon sx={{ mr: 2 }} />
          <LanguageSelects setOpen={setOpen} />
        </MenuItem>
        <ListItem sx={{ typography: "body2", py: 1, px: 2.5 }}>
          {theme.palette.mode === "dark" ? (
            <Brightness7Icon sx={{ mr: 2 }} />
          ) : (
            <Brightness4Icon sx={{ mr: 2 }} />
          )}
          <ThemeMode setOpen={setOpen} />
        </ListItem>
        <MenuItem
          onClick={() => handleLogout()}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={logoutIcon}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          {t("Logout")}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { logout })(AccountPopover);
