import React from 'react'
import { connect } from "react-redux";
import { compose } from "redux";
import {
  open_login_dialog,
  set_dialog_closing_action,
} from "../../redux/actions/auth";
const WithLoginRequired = (Component) => (props) => {
  const {
    onClick,
    onPreClick,
    isAuthenticated,
    open_login_dialog,
    set_dialog_closing_action,
    ...rest
  } = props;
  const checkLogin = (e) => {
    e.preventDefault();
    onPreClick && onPreClick(e);
    if (!isAuthenticated) {
      const dca = () => onClick(e);
      return open_login_dialog(dca);
    } else return onClick(e);
  };
  return <Component onClick={checkLogin} {...rest} />;
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default compose(
  connect(mapStateToProps, {
    open_login_dialog,
    set_dialog_closing_action,
  }),
  WithLoginRequired
);
