import React from 'react'
import { useTranslation } from "react-i18next";import { Icon } from "@iconify/react";
import { Badge, IconButton } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
useMarkNotificationAsRead,
} from "../../../hooks/notifications_hook";
import { set_chats, set_notifications } from "../../../redux/actions/auth";
import withLoginRequired from "../withLoginRequired";
const NotifiWithLogin = withLoginRequired(IconButton);
function Notification({ notifications, set_notifications, isAuthenticated }) {
const navigate = useNavigate();
const { mutate } = useMarkNotificationAsRead();
const handleMarkAllAsRead = () => {
mutate(-1);
const new_nf_list = notifications.nf_list.map((notification) => ({
...notification,
unread: false,
}));
set_notifications({ count: 0, nf_list: new_nf_list });
};
const { t } = useTranslation();return (
<IconButton
onClick={(event) =>
isAuthenticated
? (handleMarkAllAsRead(), navigate("/notifications"))
: navigate("/signin?next=/notifications")
}
sx={{ bgcolor: "inherit", color: "text.primary", ml: 1.5 }}
>
<Badge badgeContent={notifications.count} color="error">
<Icon icon="bytesize:bell" width={23} height={23} color="inherit" />
</Badge>
</IconButton>
);
}
const mapStateToProps = (state) => ({
token: state.auth.token,
notifications: state.auth.notifications,
chats: state.auth.chats,
isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {
set_notifications,
set_chats,
})(Notification);
