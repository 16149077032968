import React from 'react'
import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
export default function Confirm({ values, setClose, setValues, ...props }) {
  const msg = { blocked: "block", draft: "draft", active: "activate" };
  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, reason: e.target.value }));
  };
  const { t } = useTranslation();
  return (
    <Dialog
      open={props.open}
      onClose={setClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t(`Are you sure you want to ${msg[values.status]} this product?`)}
      </DialogTitle>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "40ch" },
        }}
        noValidate
        autoComplete="off"
      >
        {values.status !== "active" && (
          <TextField required onChange={handleChange} label="Reason" />
        )}
        <DialogActions>
          <Button onClick={setClose} variant="outlined">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              props.handleSubmit();
              setClose();
            }}
            variant="contained"
            disabled={values.status !== "active" && !values.reason}
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
