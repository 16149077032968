import React from 'react'
import { Avatar, Chip, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { fDateMonth } from "../../utils/formatTime";
export default function ProductExtend({ data }) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} mb={1}>
      {data.normal_status === "active" && (
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <Chip
            color="success"
            avatar={<Avatar>A</Avatar>}
            label={t("Active")}
          />
          <Typography variant="body2" color="text.secondary" m={`5px 0 0 0`}>
            {t("Expired on")} <b>{fDateMonth(data.normal_expired_date)}</b>
          </Typography>
        </Grid>
      )}
      {data.is_featured && (
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <Chip
            color="secondary"
            avatar={<Avatar>F</Avatar>}
            label={t("Featured")}
          />
          <Typography variant="body2" color="text.secondary" m={`5px 0 0 0`}>
            {t("Expired on")} <b>{fDateMonth(data.featured_expired_date)}</b>
          </Typography>
        </Grid>
      )}
      {data.is_promoted && (
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <Chip
            color="primary"
            avatar={<Avatar>P</Avatar>}
            label={t("Promoted")}
          />
          <Typography variant="body2" color="text.secondary" m={`5px 0 0 0`}>
            {t("Expired on")} <b>{fDateMonth(data.promoted_expired_date)}</b>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
