import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import Page from "../components/Page";
const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));
export default function Page404() {
  const { t } = useTranslation();
  return (
    <RootStyle title="404 Page Not Found ">
      <Container>
        <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
          <Typography variant="h3" paragraph>
            {t("Sorry, page not found!")}
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {t(
              "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling."
            )}
          </Typography>
          <Box
            component="img"
            src="/static/illustrations/illustration_404.svg"
            sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
          />
          <Button
            to="/"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            {t("Go to Home")}
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
