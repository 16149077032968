import React from 'react'
import CategoryIcon from "@mui/icons-material/Category";
import SellIcon from "@mui/icons-material/Sell";
import TitleIcon from "@mui/icons-material/Title";
import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { allowImageNull } from "../../pages/ProductAd/AdForm";
import { fPrice } from "../../utils/formatNumber";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    borderRadius: 10,
  },
  title: {
    pb: 0.6,
    width: "99%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  box: { width: "100%", bgcolor: "background.paper" },
}));
export default function Reviw({ product }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h4" align="center" sx={{ mb: 2 }}>
        {t("Product review")}
      </Typography>
      <Paper variant="outlined" className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            {product.image && (
              <img
                alt="img"
                src={product.image}
                style={{ borderRadius: 10 }}
                height="100%"
                width="100%"
              />
            )}
          </Grid>
          <Grid item xs={8}>
            <Box className={classes.box}>
              <List sx={{ textTransform: "capitalize" }}>
                <ListItem disablePadding divider className={classes.title}>
                  <ListItemIcon>
                    <TitleIcon />
                  </ListItemIcon>
                  <ListItemText primary={product.title} />
                </ListItem>
                <ListItem disablePadding divider sx={{ pt: 0.6, pb: 0.6 }}>
                  <ListItemIcon>
                    <CategoryIcon />
                  </ListItemIcon>
                  <ListItemText primary={t(product.category.name)} />
                </ListItem>
                <ListItem disablePadding divider sx={{ pt: 0.6, pb: 0.6 }}>
                  <ListItemIcon>
                    <SellIcon />
                  </ListItemIcon>
                  {allowImageNull.includes(product.slug) &&
                  product.price == 0 ? (
                    <ListItemText primary={t("Undisclosed")} />
                  ) : (
                    <ListItemText primary={fPrice(product.price)} />
                  )}
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
