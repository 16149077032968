import React from 'react'
import { Box, Divider } from "@mui/material";
import { createContext, memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/Alert/Alert";
import {
  fieldValidate,
  mutateFieldError,
  PostHeader,
  validateNumberOfImage,
} from "../../components/ProductAd/Post";
import AdDetailInfo from "../../components/ProductAd/Post/AdDetailInfo";
import Breadcrumb from "../../components/ProductAd/Post/Breadcrumb";
import SelectCategory from "../../components/ProductAd/Post/SelectCategory";
import { useGetLocationList } from "../../hooks/general_hooks";
import { Compressor } from "../../utils/ImageCompressor";
import { isEmpty } from "../../utils/validation";
const steps = ["Select Category", "Ad Detail info"];
const multiSelectCategory = ["clothing", "bags", "shoes", "jewelry"];
export const allowImageNull = ["job", "employee", "services"];
export const RootContext = createContext();
function AdForm({ user, category, mutate, companies, setCompanies, ...props }) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [config, setConfig] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [values, setValues] = useState(props.data);
  const [other, setOther] = useState({ page: props.page });
  const [photos, setPhotos] = useState([]);
  const [categories, setCategories] = useState({ child: [], path: [] });
  const { data, isSuccess } = useGetLocationList();
  const [address, setAddress] = useState({});
  const goBack = (payload) => {
    other.page = 1;
    if (payload === "....") setCategories({ child: category, path: ["...."] });
    else {
      let temp = category;
      for (const item of categories.path) {
        if (item !== "....") {
          const parent = temp.find((child) => child.name === item);
          if (parent.is_model) other.table = parent.slug;
          temp = temp.filter((child) => child.name === item)[0].children;
        }
        if (payload === item) break;
      }
      let path = categories.path.slice(0, categories.path.indexOf(payload) + 1);
      setCategories({ child: temp, path: path });
    }
    setOther({ ...other });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const error = fieldValidate(e);
    if (props.update || allowImageNull.includes(other.table)) {
      setErrors((prev) => ({ ...prev, ...error, detail: null }));
    } else {
      const imageError = validateNumberOfImage(config, photos);
      setErrors((prev) => ({ ...prev, ...error, ...imageError, detail: null }));
    }
    setSubmitted(true);
  };
  useEffect(() => {
    submitData();
  }, [submitted]);
  useEffect(() => {
    mutateSuccessError();
  }, [mutate.isSuccess, mutate.isError]);
  useEffect(() => {
    valueInitialize();
  }, []);
  useEffect(() => {
    addressInitialize();
  }, [isSuccess]);
  return (
    <Box px={1} width="100%">
      <PostHeader page={other.page} steps={steps} update={props.update} />
      <Breadcrumb
        setCompanies={setCompanies}
        path={categories.path}
        update={props.update}
        goBack={goBack}
        user={user}
      />
      <Divider />
      <RootContext.Provider
        value={{
          values,
          errors,
          photos,
          address,
          setValues,
          setErrors,
          setPhotos,
        }}
      >
        {other.page === 1 ? (
          <SelectCategory
            other={other}
            setOther={setOther}
            update={props.update}
            categories={categories}
            setCategories={setCategories}
            setAddress={setAddress}
          />
        ) : (
          <form onSubmit={(e) => handleSubmit(e)}>
            <AdDetailInfo
              other={other}
              update={props.update}
              setConfig={setConfig}
            />
          </form>
        )}
      </RootContext.Provider>
      <Alert
        open={other.notify}
        setOpen={() => setOther((prev) => ({ ...prev, notify: !other.notify }))}
        message={errors.detail}
        severity="error"
      />
    </Box>
  );
  function mutateSuccessError() {
    if (mutate.isSuccess) {
      if (props.update) {
        if (mutate.data.status !== "active" && mutate.data.allow_payment)
          navigate(`/post-review/${mutate.data.id}`);
        else navigate(`/detail/${mutate.data.id}`);
      } else navigate(`/post-review/${mutate.data.id}`);
    }
    if (mutate.isError) {
      window.scrollTo({ top: 250, behavior: "smooth" });
      if (typeof mutate.error === "object") {
        const error = mutateFieldError(mutate.error, values);
        if (error.detail) setOther((prev) => ({ ...prev, notify: true }));
        setErrors({ ...error });
      } else setErrors({ detail: mutate.error });
    }
  }
  function valueInitialize() {
    if (companies.id) {
      setValues((prev) => ({
        ...prev,
        company: companies.id,
        phone_number: companies.phone_number,
      }));
    } else setValues((prev) => ({ ...prev, phone_number: user.phone_number }));
    let temp = category;
    for (const item of props.subCat) {
      if (item !== "....") {
        const parent = temp.find((child) => child.name === item);
        if (parent.is_model)
          setOther((prev) => ({ ...prev, table: parent.slug }));
        let TEMP = temp.filter((child) => child.name === item)[0];
        if (TEMP.children) temp = TEMP.children;
      }
    }
    setCategories({ child: temp, path: props.subCat });
  }
  function addressInitialize() {
    if (isSuccess) {
      address.region = data;
      if (props.data.region) {
        address.zone = data.find(
          (item) => item.id === props.data.region
        ).subcity;
        address.zone.map((subcity) =>
          subcity.wereda.forEach((wereda) => {
            if (wereda.id === props.data.location) {
              address.location = subcity.wereda;
              setValues((prev) => ({ ...prev, zone: subcity.id }));
            }
          })
        );
      }
      setAddress({ ...address });
    }
  }
  async function submitData() {
    if (submitted && isEmpty(errors)) {
      let data = new FormData();
      if (!Boolean(values.price)) values.price = 0;
      const amenity = values.amenities;
      const { amenities, ...newValues } = values;
      if (newValues.condition === "New (0-4000 KM)")
        newValues.condition = "Brand New";
      if (amenity) amenity.map((item) => data.append("amenities", item));
      if (multiSelectCategory.includes(other.table)) {
        const material = newValues.materials;
        const colors = newValues.color;
        const sizes = newValues.size;
        if (material) material.map((item) => data.append("materials", item));
        if (colors) colors.map((item) => data.append("color", item));
        if (sizes) sizes.map((item) => data.append("size", item));
        const { materials, color, size, ...newData } = newValues;
        Object.keys(newData).map((key) => {
          if (newData[key] != null || newData[key] != undefined)
            data.append(key, newData[key]);
          else data.append(key, "");
        });
      } else {
        Object.keys(newValues).map((key) => {
          if (newValues[key] != null || newValues[key] != undefined)
            data.append(key, newValues[key]);
          else data.append(key, "");
        });
      }
      if (!allowImageNull.includes(other.table) && !props.update) {
        const coverImage = photos.find((item) => item.cover);
        const Images = photos.filter((item) => !item.cover);
        if (coverImage) {
          let CISC = await Compressor(coverImage.img, false);
          data.append("image", CISC);
          let CILC = await Compressor(coverImage.img, true);
          data.append("photos", CILC);
        }
        for (let file of Images) {
          let compresed = await Compressor(file.img, true);
          data.append("photos", compresed);
        }
      }
      if (props.update) mutate.mutate({ id: props.data.id, body: data });
      else mutate.mutate({ table: other.table, body: data });
    }
    setSubmitted(false);
  }
}
export default memo(AdForm);
