import React from "react";
import { Box, Link, SvgIcon, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, Outlet } from "react-router-dom";
import { ReactComponent as logoSvg } from "../config/logo/fs1.svg";
import ErrorFallbackPage from "../pages/ErrorFallbackPage";
function Copyright() {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" align="center">
      {t("Copyright") + " © "}
      <Link
        color="inherit"
        component={RouterLink}
        to="/"
        underline="none"
        sx={{
          "&:hover": {
            color: (theme) => theme.palette.secondary.main,
          },
        }}
      >
        {t("Familysooq")}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      {t("All Right Reserved")}
    </Typography>
  );
}
export default function TopLogoOnly() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems:"center"
      }}
    >
      <RouterLink to="/">
        <Container
          maxWidth="xs"
          sx={{
            my: 3,
            length: 190,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SvgIcon
            sx={{
              fontSize: "98px",
              height: "35px",
              width: "127px",
              ml: 1,
              color: "primary.main",
            }}
            component={logoSvg}
            inheritViewBox
          />
        </Container>
      </RouterLink>
      <Box
        sx={{
          minHeight: "calc(100vh - 240px)",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </Box>
  );
}
