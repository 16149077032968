import React from 'react'
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
export default function BasicCircularLoading(props) {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress {...props} />
    </Box>
  );
}
