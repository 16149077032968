import React from 'react'
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase";
import { FirebaseError, message } from "./Constants";
const generateRecaptcha = () => {
  const id = Math.random().toString(36).substring(2, 12);
  const div = document.createElement("div");
  div.setAttribute("id", id);
  document.getElementById("recaptcha-container").appendChild(div);
  window.recaptchaVerifier = new RecaptchaVerifier(
    id,
    { size: "invisible", callback: (response) => {} },
    auth
  );
};
export const send_otp = async (phoneNumber) => {
  generateRecaptcha();
  const appVerifier = window.recaptchaVerifier;
  await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
      window.otp_success = message.SEND_SUCCESS;
      window.otp_detail = null;
    })
    .catch((error) => {
      window.otp_detail = FirebaseError(error.message);
      window.otp_success = null;
    });
};
export const verify_otp = async (code) => {
  const confirmationResult = window.confirmationResult;
  if (confirmationResult) {
    await confirmationResult
      .confirm(code)
      .then((result) => {
        window.verify = true;
        window.otp_success = null;
        window.otp_detail = null;
      })
      .catch((error) => {
        window.otp_detail = FirebaseError(error.message);
        window.otp_success = null;
      });
  } else {
    window.cannotverify = true;
    window.otp_success = null;
    window.otp_detail = null;
  }
};
