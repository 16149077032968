import React from 'react'
import { Box } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import ErrorFallbackPage from "../pages/ErrorFallbackPage";
export default function NavbarLayoutWithOutAppBar() {
  return (
    <Box sx={{ height: "100vh" }}>
      <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
        <Outlet />
      </ErrorBoundary>
    </Box>
  );
}
