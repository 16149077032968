import React from 'react'
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
export default function ConfirmPayment({ handlePay, open, setClose }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={setClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("Are you sure you want to pay on FSPoint?")}
      </DialogTitle>
      <DialogActions>
        <Button onClick={setClose} variant="outlined">
          {t("Cancel")}
        </Button>
        <Button
          onClick={() => {
            handlePay();
            setClose();
          }}
          variant="contained"
        >
          {t("Pay")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
