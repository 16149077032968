import React from "react";
import { Icon } from "@iconify/react";
import { Badge, IconButton } from "@mui/material";
import { memo } from "react";
import { connect } from "react-redux";
import { set_notifications } from "../../../redux/actions/auth";
import withLoginRequired from "../withLoginRequired";
const ChatWithLogin = withLoginRequired(IconButton);
function ChatNotif({ chats }) {
  return (
    <Badge badgeContent={chats.count} color="error">
      <Icon width="23" height="23" icon="akar-icons:chat-dots" inline={true} />
    </Badge>
  );
}
const mapStateToProps = (state) => ({
  token: state.auth.token,
  notifications: state.auth.notifications,
  chats: state.auth.chats,
});
export default connect(mapStateToProps, {
  set_notifications,
})(memo(ChatNotif));
